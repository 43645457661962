import {YellowScheme, YellowInterpolator} from './OMV_Yellow';
import {BlueScheme, BlueInterpolator} from './OMV_Blue';
import {RedScheme, RedInterpolator} from './OMV_Red';

const CUSTOM_COLORS = {
  OMV_YELLOW: {
    label: 'OMV_Yellow',
    interpolator: YellowInterpolator,
    scheme: YellowScheme,
  },
  OMV_RED: {
    label: 'OMV_Red',
    interpolator: RedInterpolator,
    scheme: RedScheme,
  },
  OMV_BLUE: {
    label: 'OMV_Blue',
    interpolator: BlueInterpolator,
    scheme: BlueScheme,
  }
};

const OMV_COLORS = {
  blue: '#003366',
  red: '#E60000',
  yellow: '#FFEB00',
  green: '#66CC00'
}

export {
  CUSTOM_COLORS,
  OMV_COLORS
};
