import React from 'react';
import Map from './components/Map';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

export default function App(props) {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#003366',
        contrastText: '#ffffff'
      },
    },
    typography: {
      fontFamily: [
        'Arial Regular',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(',')}
  });
  return (
    <ThemeProvider theme={theme}>
      <Map/>
    </ThemeProvider>
  );
}
