export const BlueInterpolator = 'rgb(0, 69, 41)';
// export const yellowScheme = [
//   '#FFEB00',
//   '#f7fcb9',
//   '#d9f0a3',
//   '#addd8e',
//   '#78c679',
//   '#41ab5d',
//   '#238443',
//   '#006837',
//   '#004529'
// ];

export const BlueScheme = [
  "#FFEB00",
  // "#FFE500",
  // "#FFDE00",
  // "#FFD800",
  // "#FFD200",
  // "#FFCB00",
  // "#FFC500",
  // "#FFBF00",
  "#FFFFFF"
]
