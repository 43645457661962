import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';

const IconsLegend = ({ onClick, view }) => {
  const handleOnClick = (param) => {
    if (view === param) {
      onClick('totalProjects');
    } else {
      onClick(param);
    }
  }
  
  const styles = {
    iconsLegend: {
      display: 'flex',
      justifyContent: 'center',
      margin: '30px 0',
      minHeight: '140px',
      width: '100%',
      position: 'fixed',
      top: '0px',
      zIndex: '2'
    },
    iconsContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  };
  
  return (
    <Box sx={styles.iconsLegend}>
      <Box sx={{ ...styles.iconsContainer, ...(view === 'education') ? {border: '4px solid #003366', borderRadius: '4px' } : {border: '4px solid transparent', borderRadius: '5px' }}} onClick={() => handleOnClick('education')}>
        <img src="images/icons_educatie.jpg" />
        <Typography>
          Educație
        </Typography>
      </Box>
      <Box sx={{ ...styles.iconsContainer, ...(view === 'health') ? {border: '4px solid #003366', borderRadius: '4px' } : {border: '4px solid transparent', borderRadius: '5px' }}} onClick={() => handleOnClick('health')}>
        <img src="images/icons_sanatate.jpg" />
        <Typography>
          Sănătate
        </Typography>
      </Box>
      <Box sx={{ ...styles.iconsContainer, ...(view === 'environment') ? {border: '4px solid #003366', borderRadius: '4px' } : {border: '4px solid transparent', borderRadius: '5px' }}} onClick={() => handleOnClick('environment')}>
        <img src="images/icons_mediu.jpg" />
        <Typography>
          Mediu
        </Typography>
      </Box>
      <Box sx={{ ...styles.iconsContainer, ...(view === 'volunteering') ? {border: '4px solid #003366', borderRadius: '4px' } : {border: '4px solid transparent', borderRadius: '5px' }}} onClick={() => handleOnClick('volunteering')}>
        <img src="images/icons_voluntariat.png" />
        <Typography>
          Voluntariat
        </Typography>
      </Box>
    </Box>
  )
};

export { IconsLegend };
