export const YellowInterpolator = 'rgb(0, 69, 41)';

export const YellowScheme = [
  "#FFEB00",
  "#E60000",
  "#66CC00",
  "#2482DB",
  "darkgray",
  // "#FFD200",
  // "#FFCB00",
  // "#FFC500",
  // "#FFBF00",
  // "#FFFFFF"
]
