import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Typography
} from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { get, noop } from 'lodash';

const styles = {
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row'
  },
  divider: {
    margin: '10px 0'
  },
  link: {
    cursor: 'pointer',
    color: '#003366',
    '&:hover': {
      color: '#003366',
    }
  }
};

const externalLinks = {
  'health': 'https://fundatiaomvpetrom.ro/proiect/impreuna-din-prima-zi-1',
  'education': 'https://fundatiaomvpetrom.ro/proiect/start-in-educatie-8',
  'environment': 'https://fundatiaomvpetrom.ro/proiect/verde-pentru-viitor-7',
  'volunteering': 'https://fundatiaomvpetrom.ro/proiect/proiectele-bravoluntar-3 '
};

const DetailsDialog = ({ show, onClose, localityRef, view }) => {
  let projects = [];
  if (view === 'totalProjects') {
    projects = get(localityRef, 'current.data.projects.values', [])
  } else {
    projects = get(localityRef, 'current.data.projects.values', []).filter(project => project.type === view);
  }
  
  const renderProjectName = (project) => {
    return project.detailLink ? (
      <Link variant="text" onClick={() => window.open(project.detailLink, '_blank')} style={styles.link}>
        <Typography variant="h6">Proiectul {project.name}</Typography>
      </Link>
    ) : (
      <Typography variant="h6">Proiectul {project.name}</Typography>
    )
  }
  
  const renderProjects = () => {
    return projects.map((project, index) => {
      return (
        <div key={`project_${index}`}>
          {renderProjectName(project)}
          <Typography variant="subtitle1">{project.description}</Typography>
          <Typography variant="subtitle2">Partener: {project.partener}</Typography>
          <Typography variant="subtitle2">Anul: {project.year ? project.year : '-'}</Typography>
          <Box style={styles.divider}>
            <Divider />
          </Box>
        </div>
      )
    });
  }
  
  return (
    <Dialog open={show} onClose={onClose} maxWidth="lg" fullWidth={true}>
      <DialogTitle>
        <Box style={styles.dialogHeader}>
          <Typography variant="h4" color="primary">
            {`${localityRef.current?.data.label} (${localityRef.current?.data.countyId})`}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {renderProjects()}
      </DialogContent>
      {view !== 'totalProjects' && (
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => window.open(externalLinks[view], '_blank')}>
            Află mai multe
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
};

export { DetailsDialog };
