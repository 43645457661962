import React, { useState, useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';

import { isEmpty, isUndefined, get, cloneDeep } from 'lodash';

import { MapOfRomania, MapOfRomanianCounty } from '../external';

import { Box, Button, Grid, Popover, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CachedIcon from '@material-ui/icons/Cached';

import dataSources from '../utils/dataSources';
import { scales } from '../utils/mapConfigOptions';
import { CUSTOM_COLORS } from '../customColors';

import { DetailsDialog } from './DetailsDialog/DetailsDialog';
import { IconsLegend } from './IconsLegend/IconsLegend';
import './ovverrides.css';

const highlightMap = {
  'health': '#E60000',
  'environment': '#66CC00',
  'education': '#FFEB00',
  'volunteering': '#2482DB',
  'other': 'darkGray',
  'default': 'lightGray'
};

const getStyles = (highlight) => {
  return {
    label: {
      fill: 'black',
      fontSize: 10,
      fontFamily: 'sans-serif',
      pointerEvents: 'none',
      background: 'white',
    },
    primaryPolygon: {
      strokeWidth: 0.5,
      stroke: 'white',
      strokeOpacity: 1,
      strokeLinejoin: 'round',
      fillOpacity: 0.9,
      ...(highlight !== 'default' && { fill: 'lightgray!important' }),
      transition: 'fill 0.5s ease',
      '&:hover': {
        stroke: 'white',
        strokeWidth: 0.5,
        strokeOpacity: 1,
        fillOpacity: 1,
      },
    },
    highlightedPolygon: {
      fill: `${highlightMap[highlight]}!important`,
      strokeWidth: 0.5,
      stroke: 'white',
      strokeOpacity: 1,
      strokeLinejoin: 'round',
      fillOpacity: 1,
    },
    point: {
      fill: '#003366',
      strokeLinecap: 'round',
      strokeOpacity: 0.75,
      pointerEvents: 'none',
    },
    pointLabel: {
      fontSize: 12,
      background: 'rgba(255, 255, 255, 0.75)',
      fontFamily: 'Arial Regular',
      pointerEvents: 'none',
      display: 'inline-block',
      whiteSpace: 'nowrap',
      padding: 5,
    },
    paper: {
      padding: 5,
      color: 'black',
    },
    root: {
      display: 'flex',
      height: '99%',
      width: '100%',
      position: 'absolute',
      alignItems: 'center',
      justifyItems: 'center'
    },
    formControl: {
      margin: 3,
    },
    selectedFilter: {
      fontWeight: 'bold',
    },
    selectedSecondaryFilter: {
      background: 'lightgray',
      '&:hover': {
        background: 'lightgray',
      }
    },
    normalFilter: {
      '&:hover': {
        background: 'lightgray',
      }
    }
  }
};

const generateTooltip = (data, element, key, ref, view) => {
  const style = {
    display: 'inline-block',
    background: 'white',
    padding: '0.25rem 1rem',
    whiteSpace: 'nowrap',
    border: '1px solid transparent',
    borderRadius: '10px',
  };

  ref.current = {
    data,
    element,
    key
  };

  return ReactDOMServer.renderToString(
    <Box style={style}>
      <Typography variant="subtitle1">{data ? `${data.label}` : 'N/A'}</Typography>
      <Typography variant="subtitle2">
       {`Comunități: ${data.value[view.current]}`}
      </Typography>
    </Box>
  );
};

const generateCountyTooltip = (data, element, key, ref, view) => {
  let projectsCount = 0;
  const style = {
    display: 'inline-block',
    background: 'white',
    padding: '0.25rem 1rem',
    whiteSpace: 'nowrap',
  };

  ref.current = {
    data,
    element,
    key
  };

  if (isUndefined(data.projects)) {
    return null;
  }

  if (view === 'totalProjects') {
    projectsCount = get(data, ['projects', 'values'], []).length;
  } else {
    projectsCount = get(data, ['projects', 'values'], []).filter(project => project.type === view.current).length;
  }

  return ReactDOMServer.renderToString(
    <Box style={style}>
      <Typography variant="h6">{data ? `${data.label}` : 'N/A'}</Typography>
      <Typography variant="subtitle2">
        {`Comunități: ${projectsCount}`}
      </Typography>
    </Box>
  );
};

const getCountyData = (atuData, countyId) => {
  // deep copy of the filtered array
  const data = atuData.filter(atu => atu.countyId === countyId);
  return JSON.parse(
    JSON.stringify(data)
  );
};

export default function Map(props) {
  // const [view, setView] = useState('totalProjects');
  const view = useRef('totalProjects');
  const genericFilterKey = useRef('totalProjects');
  const [loading, setLoading] = useState(true);
  const [localityModalOpen, setLocalityModalOpen] = useState(false);
  const [projectDetailsOpen, setProjectDetailsOpen] = useState(false);
  const [highlight, setHighlight] = useState('default');
  const [countyHighlight, setCountyHighlight] = useState('default');
  const [filters, setFilters] = useState([]);
  const filterRef = useRef([]);
  const mapDataRef = useRef(null);
  const countyRef = useRef(null);
  const localityRef = useRef(null);
  const legendHoverRef = useRef(null);
  const styles = getStyles(highlight);
  const countyStyles = getStyles(countyHighlight);
  const primaryClasses = makeStyles(styles)();
  const secondaryClasses = makeStyles(countyStyles)();

  const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
  }));

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [mapData, setMapData] = useState({
    primaryMapData: [],
    secondaryMapData: [],
    pointMapData: [],
    selectedCountyData: [],
    selectedCounty: '',
    selectedYear: 'totalProjects',
  });

  const [mapConfig, setMapConfig] = useState({
    minHeight: 500,
    minWidth: 500,
    showLabels: true,
    showPoints: true,
    showPointLabels: true,
    labels: [],
    pointNames: [''],
    pointTypes: ['Municipiu reședință de județ'],
    scale: scales[0],
    color: CUSTOM_COLORS.OMV_YELLOW,
  });

  const countyMapConfig = {
    minHeight: 500,
    minWidth: 300,
    showLabels: true,
    showPoints: true,
    showPointLabels: true,
    labels: ['AB', 'BN'],
    pointNames: ['Cluj-Napoca', 'Lugoj', 'Zimnicea', 'București'],
    pointTypes: ['Municipiu reședință de județ'],
    scale: scales[0],
    color: CUSTOM_COLORS.OMV_YELLOW,
  }

  const onCountyClick = d => {
    const countyData = getCountyData(mapData.secondaryMapData, d.id);

    setMapData({
      ...mapData,
      selectedCountyData: countyData,
      selectedCounty: d.id,
    });
  };
  
  const labelMap = {
    year: 'An',
    name: 'Program',
    partener: 'Partener',
    type: 'Domeniu',
  };
  
  const domainLabelMap = {
    health: 'Sănătate',
    education: 'Educație',
    environment: 'Mediu',
    volunteering: 'Voluntariat',
  }
  
  const getLabel = (key, value) => {
    if (key === 'type') {
        return domainLabelMap[value];
    }
    
    return value;
  };
  
  
  const buildFilters = (data) => {
    const lookupKeys = ['type', 'name', 'partener', 'year'];
    const results = [];
    
    data.forEach(locality => {
      if (locality.projects && locality.projects.values) {
        locality.projects.values.forEach(project => {
          lookupKeys.forEach(key => {
            const value = project?.filterName && key === 'name' ? project.filterName : project[key];
            if (value) {
              const result = results.find(r => r.key === key);
              if (result) {
                const valueResult = result.values.find(v => v.value === value);
                if (valueResult) {
                  valueResult.count++;
                } else {
                  result.values.push({
                    key: result.key,
                    value,
                    count: 1,
                    label: getLabel(key, value),
                    selected: false,
                  });
                }
              } else {
                results.push({
                  key,
                  label: labelMap[key],
                  selected: false,
                  values: [
                    {
                      value,
                      count: 1,
                      label: getLabel(key, value),
                      selected: false,
                      key: key,
                    }
                  ]
                });
              }
            }
          });
        });
      }
    });
    
    return results;
  };

  useEffect(() => {
    const promises = [
      dataSources.countyTotalProjectsDataUrl,
      dataSources.countyProjectsDataUrl,
      dataSources.cityProjectsDataUrl,
    ].map(url => fetch(url).then(response => response.json()));

    Promise.all(promises).then(([countyTotalData, countyProjectsData, cityProjectsData]) => {
      mapDataRef.current = {
        primaryMapData: countyTotalData,
        secondaryMapData: countyProjectsData,
        pointMapData: cityProjectsData,
        selectedCountyData: getCountyData(countyProjectsData, 'CJ'),
        selectedCounty: 'CJ',
        selectedYear: 2019,
      };
      setMapData({
        primaryMapData: countyTotalData,
        secondaryMapData: countyProjectsData,
        pointMapData: cityProjectsData,
        selectedCountyData: getCountyData(countyProjectsData, 'CJ'),
        selectedCounty: 'CJ',
        selectedYear: 2019,
      });
      
      const filterResults = buildFilters(cloneDeep(countyProjectsData));
      filterRef.current = filterResults;
      setFilters(filterResults);
      setTimeout(() => {
        setLoading(false);
      }, 2800);
    });
  }, []);
  
  useEffect(() => {
    const newFilters = buildFilters(cloneDeep(mapData.secondaryMapData));
    const result = newFilters.map(filter => {
      const resultFilters = [];
      
      filter.values.forEach(value => {
        const initFilter = filters.find(f => {
          return f.key === filter.key && f.values.some(v => v.value === value.value && v.selected)
        });
        if (initFilter) {
          resultFilters.push({
            ...value,
            selected: true,
          });
        } else {
          resultFilters.push(value);
        }
      });
      
      if (resultFilters.length !== 0) {
        return {
          ...filter,
          values: resultFilters,
        };
      }
      return filter;
    });
    setFilters(result);
  }, [JSON.stringify(mapData.secondaryMapData)]);
  
  const getFilterView = (filters, selectedFilters) => {
    let filterView = selectedFilters.find(filter => filter.key === 'type');
    const typeFilters = filters.filter(filter => filter.key === 'type');
    
    if (typeFilters.length !== 0 && typeFilters[0].values?.length === 1) {
      filterView = typeFilters[0].values[0];
    }
    
    return filterView;
  }

  useEffect(() => {
    const selectedFilters = filters.map(filter => filter.values)
      .flat()
      .filter(value => value.selected);
    
    const filterView = getFilterView(filters, selectedFilters);
    
    if (filterView) {
      view.current = filterView.value;
      genericFilterKey.current = 'totalProjects';
    } else if (selectedFilters.length !== 0) {
      view.current = 'totalProjects';
      genericFilterKey.current = 'other';
    } else {
      view.current = 'totalProjects';
      genericFilterKey.current = 'totalProjects';
    }

    if (selectedFilters.length !== 0) {
      const newSecondaryMapData = mapDataRef.current.secondaryMapData.map(county => {
        const countyProjects = get(county, ['projects', 'values'], []).filter(project => {
          if (view.current === 'totalProjects') {
            return selectedFilters.every(filter => {
              return filter.value === project[filter.key] || project[filter.key].includes(filter.value);
            });
          }
          return selectedFilters.every(filter => {
            return (filter.value === project[filter.key] || project[filter.key].includes(filter.value)) && project.type === view.current;
          });
        });
        return {
          ...county,
          value: {
            [view.current]: countyProjects.length,
          },
          projects: {
            ...county.projects,
            values: countyProjects,
          }
        }
      });

      const filterCounty = (c, county) => {
        if (view.current === 'totalProjects') {
          return c.countyId === county.id && get(c, ['projects', 'values'], []).length !== 0
        }
        return c.countyId === county.id && get(c, ['projects', 'values'], []).filter(project => project.type === view.current).length !== 0
      }
  
      const newPrimaryMapData = mapDataRef.current.primaryMapData.map(county => {
        return {
          ...county,
          value: {
            ...county.value,
            totalProjects: newSecondaryMapData.filter((c) => filterCounty(c, county)).length,
            [view.current]: newSecondaryMapData.filter((c) => filterCounty(c, county)).length,
          }
        }
      });
  
      setMapData({
        ...mapData,
        primaryMapData: newPrimaryMapData,
        secondaryMapData: newSecondaryMapData,
        selectedCountyData: getCountyData(newSecondaryMapData, mapData.selectedCounty),
      });
    } else {
      mapDataRef.current && setMapData({
        ...mapDataRef.current,
        selectedCountyData: getCountyData(mapDataRef.current.secondaryMapData, mapData.selectedCounty),
        selectedCounty: mapData.selectedCounty
      });
    }
  }, [JSON.stringify(filters)]);

  const onLocalityClick = () => {
    const data = get(localityRef, 'current.data.projects.values', []);
    if (!isEmpty(data)) {
      setProjectDetailsOpen(true);
    }
  }

  const handleFilterClick = (filterValue) => {
    const newFilters = filters.map(filter => {
        if (filter.key === filterValue.key) {
          const secondaryFilters = filter.values.map(value => {
            if (value.key === filterValue.key && value.value === filterValue.value) {
              return {
                ...value,
                selected: !filterValue.selected,
              }
            }
            return {
              ...value,
              selected: false,
            }
          });
          return {
            ...filter,
            values: secondaryFilters,
            selected: secondaryFilters.some(value => value.selected)
          }
        }
        return filter;
    });
    setFilters(newFilters);
  };

  const renderFilters = () => {
    return filters.map(filter => {
      const selectedSubFilter = filter.values.find(value => value.selected);
      
      return (
        <>
          <Tooltip title={selectedSubFilter ? selectedSubFilter.label : ''} placement="top">
            <Box style={{
              display: 'flex',
              flexDirection: 'row',
              cursor: 'pointer',
              width: '200px'
            }}>
              <Typography variant="h6" color="primary" onClick={handleClick}
                          className={selectedSubFilter ? primaryClasses.selectedFilter : ''}>{filter.label}</Typography>
              <Typography variant="h6" color="primary" onClick={handleClick}
                          className={selectedSubFilter ? primaryClasses.selectedFilter : ''}>:</Typography>
              <Typography variant="h6" color="primary" onClick={handleClick}
                          style={{
                            margin: '0 5px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                          }}
                          className={selectedSubFilter ? primaryClasses.selectedFilter : ''}>{selectedSubFilter ? selectedSubFilter.label : ''}</Typography>
            </Box>
          </Tooltip>
          <Popover
            id={filter.key}
            open={anchorEl && anchorEl.innerText === filter.label}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {filter.values.map(filterValue => {
              return (
                <Box className={filterValue.selected ? primaryClasses.selectedSecondaryFilter : primaryClasses.normalFilter} style={{cursor: 'pointer'}}>
                  <Typography className={classes.typography}
                              onClick={() => handleFilterClick(filterValue)}>{filterValue.label}</Typography>
                </Box>
              );
            })}
          </Popover>
        </>
      )
    });
  }

  return (
    <>
      {loading && (
        <Box className="logo-loader">
          <img src="images/Logo_Fundatie_1.png" />
        </Box>
      )}
      <Box minWidth="1100px" className="map-container">
        <IconsLegend onClick={(param) => {
            handleFilterClick({
              count: 44,
              key: "type",
              label: domainLabelMap[param],
              selected: false,
              value: param
            })
          setHighlight('default');
          setCountyHighlight('default');
        }} view={view.current} />
        <Grid container spacing={1} style={{position: 'relative', top: '180px'}}>
          <Grid item xs={6}>
            <MapOfRomania
              {...mapConfig}
              primaryMapData={mapData.primaryMapData}
              dataKey={view.current}
              exactDataKey={genericFilterKey.current}
              scale={mapConfig.scale.scale}
              color={mapConfig.color[mapConfig.scale.colorType]}
              tooltip={(data, element, key) => generateTooltip(data, element, key, countyRef, view)}
              classes={primaryClasses}
              onClick={onCountyClick}
              onLegendHover={(prop) => setHighlight(prop)}
              legendHoverRef={legendHoverRef}
            />
          </Grid>
          <Grid item xs={6}>
            <MapOfRomanianCounty
              {...countyMapConfig}
              primaryMapData={mapData.selectedCountyData}
              dataKey={view.current}
              exactDataKey={genericFilterKey.current}
              countyId={mapData.selectedCounty || "CJ"}
              pointNames={[]}
              scale={mapConfig.scale.scale}
              color={mapConfig.color[mapConfig.scale.colorType]}
              tooltip={(data, element, key) => generateCountyTooltip(data, element, key, localityRef, view)}
              classes={secondaryClasses}
              onClick={onLocalityClick}
              onLegendHover={(prop) => setCountyHighlight(prop)}
            />
          </Grid>
        </Grid>
        <Box style={{
          borderTop: '5px solid #6c0',
          position: 'fixed',
          bottom: '0px',
          width: '100%',
          height: '50px',
          display: 'flex',
          alignItems: 'center',
          background: 'white',
          overflow: 'auto',
        }}>
          <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '40px', width: '99%' }}>
            {renderFilters()}
            <Box
              onClick={() => {
                setFilters(filterRef.current);
              }}
              style={{ cursor: 'pointer', color: '#3f51b5', display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6" color="primary">Reset</Typography>
              <CachedIcon />
            </Box>
          </Box>
        </Box>
        <DetailsDialog
          show={projectDetailsOpen}
          onClose={() => setProjectDetailsOpen(false)}
          localityRef={localityRef}
          view={view.current}
        />
      </Box>
    </>
  );
}
